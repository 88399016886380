import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import { themes } from "../styles/ColorStyles"
import Markdown from "markdown-to-jsx"

interface Props {
  node: any
}

const UpdatesSection = (props: Props) => {
  const { node } = props
  const {
    title,
    description,
    releaseDate,
    coverImage,
    childContentfulUpdateContentUpdateTextNode,
  } = node

  return (
    <Wrapper className="update-wrapper">
      <Illustration className="update-illustration" src={coverImage.file.url} alt="illustration" />
      <Container>
        <TextWrapper>
          <Date>{releaseDate}</Date>
          <Title>{title}</Title>
          <Caption>{description}</Caption>
        </TextWrapper>
        <InfoWrapper>
          <Markdown
            options={{
              overrides: {
                h2: { component: TextStyles.H4 },
                p: { component: TextStyles.MediumText },
              },
            }}
          >
            {childContentfulUpdateContentUpdateTextNode.update}
          </Markdown>
        </InfoWrapper>
      </Container>
    </Wrapper>
  )
}

export default UpdatesSection

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  max-width: 1234px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 1200px) {
    justify-items: center;
  }

  @media (max-width: 440px) {
    grid-template-columns: 1fr;
  }
`

const Illustration = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 0;
  background: #ffffff;
  border-radius: 20px;
`

const TextWrapper = styled.div`
  display: grid;
  gap: 20px;
  max-width: 290px;
  height: fit-content;

  @media (max-width: 440px) {
    max-width: 500px;
  }
`

const Date = styled(TextStyles.BodyMain)`
  opacity: 0.7;
`

const Title = styled(TextStyles.H4)``

const Caption = styled(TextStyles.BodyMain)`
  opacity: 0.7;
`

const InfoWrapper = styled.div`
  width: 100%;

  h4 {
    padding-bottom: 20px;
  }

  a:link {
    text-decoration: none;
    color: #8338a8;

    @media (prefers-color-scheme: dark) {
      color: #00cffd;
    }
  }

  a:visited {
    text-decoration: none;
    color: #8338a8;

    @media (prefers-color-scheme: dark) {
      color: #00cffd;
    }
  }

  p {
    opacity: 0.7;
    line-height: 120%;
  }

  li {
    color: ${themes.light.text1};

    @media (prefers-color-scheme: dark) {
      color: ${themes.dark.text1};
    }
  }
`
