import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"
import UpdatesSection from "../components/sections/UpdatesSection"
import * as TextStyles from "../components/styles/TextStyles"
import { StaticImage } from "gatsby-plugin-image"
import PillButton from "../components/buttons/PillButton"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
    allContentfulUpdateContent: any
  }
}

const Updates = (props: Props) => {
  const { data } = props
  const { allContentfulUpdateContent } = data
  const [isMore, setIsMore] = useState(false)

  const firstUpdate = allContentfulUpdateContent.edges[0]
  const fullUpdates = allContentfulUpdateContent.edges.slice(
    1,
    allContentfulUpdateContent.edges.length
  )
  const updates = allContentfulUpdateContent.edges.slice(1, 10)

  const handleUpdates = () => {
    if (isMore) {
      return fullUpdates
    } else {
      return updates
    }
  }

  return (
    <PageWrapper>
      <Layout {...data}>
        <Background>
          <Wave />
        </Background>
        <Wrapper>
          <Title>Angle Updates</Title>
          <Description>Latest updates from Angle. New devices are added regularly.</Description>
          <Container>
            <StaticImage
              className="illustration"
              src="../images/illustrations/illustration15.webp"
              alt="illustration"
            />
            <BlurContainer>
              <UpdatesSection {...firstUpdate} />
            </BlurContainer>
          </Container>
          <SectionContainer>
            {handleUpdates().map((edge, index) => (
              <UpdatesSection key={index} {...edge} />
            ))}
          </SectionContainer>
          <PillButton
            title={isMore ? "Show less" : "Show more"}
            onClick={() => setIsMore(!isMore)}
          />
        </Wrapper>
      </Layout>
    </PageWrapper>
  )
}

export default Updates

export const query = graphql`
  query UpdatesQuery {
    contentfulMetadata(slug: { eq: "updates" }) {
      ...MetadataFragment
    }

    allContentfulUpdateContent(sort: { fields: releaseDate, order: DESC }) {
      edges {
        node {
          releaseDate(formatString: "DD.MM.YYYY")
          title
          description
          coverImage {
            file {
              url
            }
          }
          childContentfulUpdateContentUpdateTextNode {
            update
          }
        }
      }
    }
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`

const PageWrapper = styled.div`
  position: relative;

  .navigation-title {
    color: #ffffff;
  }
`

const Wrapper = styled.div`
  display: grid;
  position: relative;
  gap: 20px;
  padding: 200px 20px;
`

const Container = styled.div`
  display: grid;
  max-width: 1234px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  .illustration {
    max-width: 620px;
    margin: 0 auto;
    opacity: 0;
    animation: ${fadeIn} 1s 0.5s linear forwards;
  }

  .update-wrapper {
    grid-template-columns: 1fr;
  }

  .update-illustration {
    display: none;
  }
`

const BlurContainer = styled.div`
  padding: 30px;
  background: rgba(238, 234, 254, 0.3);
  backdrop-filter: blur(80px);
  border-radius: 30px;
`

const SectionContainer = styled.div`
  display: grid;
  gap: 150px;
  padding-top: 70px;
`

const Title = styled(TextStyles.H1H2)`
  text-align: center;
  color: rgba(255, 255, 255, 1);
`

const Description = styled(TextStyles.MediumText)`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 1050px;
  background: linear-gradient(180deg, #361d92 0%, rgba(156, 85, 187, 0) 100%);

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(178.05deg, #041041 3.09%, #3d1e95 47.28%, rgba(2, 6, 51, 0) 85.68%);
  }
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 1716px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("/images/backgrounds/update-wave.webp");

  @media (max-width: 1200px) {
    background-size: 1440px;
  }
`
